import React, { useEffect } from "react";
import "./style.css";
import Header from "../../Containers/Header";
import TeamCard from "../../Components/TeamCard";
import data from './data.json';

const Team = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Header />
            <div className="container-fluid team-container mb-5">
                <div className="row">
                    <div className="col-md-8 mx-auto text-center mt-5">
                        <h1 className="mb-4">Our Team</h1>
                        <h5>
                            We are very glad to introduce our team. Having such a hardworking
                            and efficient team is always a key for our success.
                        </h5>
                    </div>
                    <div className="col-md-10 mb-5 mt-3 mx-auto text-center">
                        <div className="row justify-content-center">
                            {/* <h5 className="mt-5">Comming soon</h5> */}
                            {data.map((member, index) => (
                                <TeamCard
                                    key={index}
                                    name={member.name}
                                    prof={member.prof}
                                    img={member.img}
                                    bio={member.bio}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Team;
